import request from '@/utils/request';
const payCenterApi = {
  // 获取平台支付列表
  getPayPlatformList() {
    return request.get('/payCenter/payPlatform/findDefaultList');
  },
  // 修改默认设置
  updatePlatformList() {
    return request.post('/payCenter/payPlatform/updateDefaultList');
  },
  // 修改支付平台默认设置
  updatePayPlatformDefault(params: any) {
    return request.post('/payCenter/payPlatform/updateDefault', params);
  },
  // 分页查询平台账户默认数据
  findPayAccountDefaultPages(params: any) {
    return request.post('/payCenter/payAccount/findDefaultPages', params);
  },

  // 创建平台账户默认数据
  findPayAccountCreateDefault(params: any) {
    return request.post('/payCenter/payAccount/createDefault', params);
  },
  // 创建平台账户默认数据
  findPayConfigForm(payPlatform: string) {
    return request.get(
      `/payCenter/payPlatform/findPayConfigForm/${payPlatform}`,
    );
  },

  // 修改平台账户默认数据
  updatePayAccountCreateDefault(params: any) {
    return request.post('/payCenter/payAccount/updateDefault', params);
  },
  // 创建或者修改平台账户默认数据
  createOrUpdatePayAccountCreateDefault(
    type: 'create' | 'update',
    params: any,
  ) {
    const url =
      type === 'create'
        ? '/payCenter/payAccount/createDefault'
        : '/payCenter/payAccount/updateDefault';
    return request.post(url, params);
  },

  // 查询支付平台默认下拉框数据
  findPayPlatformDefaultOptions() {
    return request.get('/payCenter/payPlatform/findDefaultOptions');
  },

  // 获取客户列表
  getCustomerList(params: any) {
    return request.post('/usercenter/customerInfo/findPage', params);
  },
  // 查询支付场景列表
  getSceneList(params: any) {
    return request.post('/payCenter/payScene/findList', params);
  },

  // 查询支付场景下拉框
  getSceneSelect() {
    return request.get('/payCenter/payScene/findOptions');
  },

  // 批量创建
  batchCreate(params: any) {
    return request.post('/payCenter/payScene/batchCreate', params);
  },

  // 启用
  enable(id: string | number) {
    return request.get(`/payCenter/payScene/enable/${id}`);
  },

  // 禁用
  disable(id: string | number) {
    return request.get(`/payCenter/payScene/disable/${id}`);
  },

  // 创建公司相关接口
  // 查询默认分页
  findDefaultPages(params: any) {
    return request.post('/payCenter/payCompany/findDefaultPages', params);
  },
  // 创建默认公司
  createDefault(params: any) {
    return request.post('/payCenter/payCompany/createDefault', params);
  },
  // 修改默认公司
  updateDefault(params: any) {
    return request.post('/payCenter/payCompany/updateDefault', params);
  },

  // 获取商户号列表
  getValidAccount(params: any ) {
    return request.get(`/payCenter/payPollingRule/getValidAccount`, {params});
  },
  // 获取规则详情
  getRuleDetail(id: any) {
    return request.get(`/payCenter/payPollingRule/getRuleDetail/${id}`);
  },
  // 查询分页
  getRuleFindPage(params: any) {
    return request.post(`/payCenter/payPollingRule/findPage`, params);
  },
  // 删除规则
  deleteRule(id: any) {
    return request.post(`/payCenter/payPollingRule/deleteRule/${id}`);
  },
  // 创建轮询规则
  createRule(params: any) {
    return request.post(`/payCenter/payPollingRule/createRule`, params);
  },
  // 查询可用的支付平台
  getValidPayPlatform(params: any) {
    return request.get(`/payCenter/payPollingRule/getValidPayPlatform`, {params});
  },
  // 查询接收人列表
  getUserPage(params:any){
    return request.post(`/usercenter/sys/user/pages`, params);
  },
  saveWarningConfig(params:any){
    return request.post(`/payCenter/payPlatform/saveWarningConfig`, params);
  },
  getWarningConfig(){
    return request.get(`/payCenter/payPlatform/getWarningConfig`);
  }
};

export default payCenterApi;
