<template>
  <main-content>
    <!-- 支付场景 -->
    <el-button type="primary" @click="addPayScene">添加支付场景</el-button>
    <el-table :data="tableData" height="calc(100vh - 240px)">
      <el-table-column label="场景" prop="sceneName" min-width="180">
        <template slot-scope="scope">
          {{ scope.row.sceneName }}
        </template>
      </el-table-column>
      <el-table-column label="标识" prop="scene" min-width="180">
        <template slot-scope="scope">
          {{ scope.row.scene }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="disable" min-width="150">
        <template slot-scope="scope">
          {{ scope.row.disable ? '禁用' : '启用' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="channelId" align="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="handleSwitchStatus(scope.row.id, scope.row.disable)"
            >{{ scope.row.disable ? '启用' : '禁用' }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <PaymentScene
      v-if="visible"
      :visible.sync="visible"
      @confirm="
        getSceneList();
        visible = false;
      "
    />
  </main-content>
</template>
<script>
import PaymentScene from '../components/PayScene.vue';
import MainContent from '@/components/MainContent.vue';
import payCenterApi from '@/api/paycenter';
export default {
  components: {
    PaymentScene,
    MainContent,
  },
  data() {
    return {
      tableData: [],
      visible: false,
    };
  },
  created() {
    this.getSceneList();
  },
  methods: {
    async getSceneList() {
      try {
        const { data } = await payCenterApi.getSceneList({
          disable: '',
        });
        this.tableData = data;
      } catch (err) {
        console.log(err);
      }
    },
    addPayScene() {
      this.$nextTick(() => {
        this.visible = true;
      });
    },

    // 编辑item
    async handleSwitchStatus(id, status) {
      try {
        let res;
        if (status) {
          res = await payCenterApi.enable(id);
          console.log(res);
        } else {
          res = await payCenterApi.disable(id);
        }

        if (!res.data) {
          this.$message.success('保存失败');
        } else {
          this.$message.success('保存成功');
          this.getSceneList();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
