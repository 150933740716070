<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="30%"
    :close-on-click-modal="false"
    @close="handleCancel"
  >
    <el-form :model="formData" ref="form" :rules="formRule" class="dialog-form">
      <el-form-item label="场景：" prop="payScenes" label-width="100px">
        <el-select
          v-model="formData.payScenes"
          multiple
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in sceneArray"
            :label="item.label"
            :key="item.value"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="loading">
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import payCenterApi from '@/api/paycenter';
export default {
  props: {
    title: {
      type: String,
      default: '添加支付场景',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        payScenes: [],
      },
      loading: false,
      formRule: {
        payScenes: [
          { required: true, message: '请选择场景', trigger: 'change' },
        ],
      },
      sceneArray: [],
    };
  },
  created() {
    this.getSceneArray();
  },
  methods: {
    handleCancel() {
      this.$emit('update:visible', false);
      this.$refs['form'].resetFields();
    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const payScenes = this.sceneArray
            .filter(item => this.formData.payScenes.includes(item.value))
            .map(item => ({
              scene: item.value,
              sceneName: item.label,
              disable: false,
            }));
          this.loading = true;
          payCenterApi
            .batchCreate(payScenes)
            .then(res => {
              if (res.success === true) {
                this.$message.success('保存成功');
                this.handleCancel();
                this.$emit('confirm');
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    async getSceneArray() {
      try {
        const { data } = await payCenterApi.getSceneSelect();
        this.sceneArray = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-form {
  .el-form-item {
    display: flex;
    /deep/.el-form-item__content {
      width: 80%;
      margin-left: 0 !important;
    }
  }
}
</style>
